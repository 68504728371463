import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
} from "@angular/core";
import { SurveyHeaders, ViewAs } from "../common/models";
import { Survey } from "../../shared/entities";
import { Router } from "@angular/router";

@Component({
  selector: "srvy-my-surveys",
  templateUrl: "./my-surveys.component.html",
  styleUrls: ["./my-surveys.component.css"],
})
export class MySurveysComponent implements OnInit, OnChanges {
  @Input() surveyHeaders: SurveyHeaders = new SurveyHeaders();
  @Input() selectedUser: ViewAs;
  @Input() loggedUser: ViewAs;
  // tslint:disable-next-line:no-output-rename
  @Output("backToDashbordEvent") backToDashbord: EventEmitter<ViewAs> =
    new EventEmitter();
  public networkId: string = "";
  public displayName: string = "";
  public personalActiveSurveys: Survey[];
  public managerActiveSurveys: Survey[];
  public personalCompletedSurveys: Survey[];
  public managerCompletedSurveys: Survey[];
  public managersHierarchy: string[];

  constructor(private router: Router) {}

  ngOnInit() {}

  clearSurveys() {
    this.personalActiveSurveys = [];
    this.managerActiveSurveys = [];
    this.personalCompletedSurveys = [];
    this.managerCompletedSurveys = [];
  }

  populateSurveys(
    headerSurveys: Survey[],
    pursonalSurveys: Survey[],
    managerSurveys: Survey[]
  ) {
    const keys = headerSurveys ? Object.keys(headerSurveys) : [];
    for (const key of keys) {
      var sur = headerSurveys[key];
      if (!sur.assignedTo) {
        pursonalSurveys.push(sur);
      } else {
        managerSurveys.push(sur);
      }
    }
  }

  ngOnChanges() {
    this.clearSurveys();
    this.setNameAndNetworkId(this.selectedUser);

    if (this.networkId === this.surveyHeaders.headOfCompany) {
      this.surveyHeaders.activeSurveys =
        this.surveyHeaders.activeSurveys.filter((x) => !!x.assignedTo);
    }

    this.populateSurveys(
      this.surveyHeaders.activeSurveys,
      this.personalActiveSurveys,
      this.managerActiveSurveys
    );

    this.populateSurveys(
      this.surveyHeaders.completedSurveys,
      this.personalCompletedSurveys,
      this.managerCompletedSurveys
    );

    this.managersHierarchy =
      !!this.surveyHeaders &&
      !!this.surveyHeaders.managerDirectReports &&
      this.surveyHeaders.managerDirectReports.length > 0
        ? this.surveyHeaders.managerDirectReports.sort()
        : null;
  }

  activeRemainingDays(header: Survey): number {
    const today = new Date();
    const days = Math.round(
      (new Date(header.endDate).getTime() - today.getTime()) /
        (1000 * 60 * 60 * 24)
    );

    // + 1 to include end date
    return days + 1;
  }

  navigateToSurvey(surveyId: string): void {
    this.router.navigate(["/survey", surveyId, this.networkId]);
  }

  dashboardTitle() {
    const name = "Dashboard - " + this.displayName;
    return name;
  }

  setNameAndNetworkId(user: ViewAs) {
    this.networkId = user.networkId;
    this.displayName = user.name;
  }

  goToDashboard(user: ViewAs) {
    this.backToDashbord.emit(user);
  }

  navigateToSearch() {
    this.router.navigate(["/search"]);
  }
}
